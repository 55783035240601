import React from 'react'
import {
    Container,
    Typography,
    Box,
    Link,
    Grid,
    MenuList,
    Paper
} from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from '@mui/material/ListItemIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {Trans, useTranslation} from 'next-i18next'
import {defaultComponent} from "../../utils/i8next/component";
import Image from "next/legacy/image";
const Footer = () => {
    const {t} = useTranslation(['footer']);

    return (
        <Box style={{ backgroundColor: '#f1f2f6' }} mt={10} pt={5}>
            <Container maxWidth='lg'>

                <Grid container spacing={5}>

                    <Grid item xs={12} md={6}>
                        <Paper sx={{   height: 331, p:2 ,borderTopRightRadius: '40px',borderBottomLeftRadius: '40px'}}>
                            <Box sx={{
                                textAlign:'right',
                                fontFamily: 'Lato',
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: "1.2rem",
                                lineHeight: "11px",
                                pr:3,
                                mb:1
                            }}>
                                {t('_TKEY_FOOTER_BRUXELLES_MOBILITE_TEXT_1', { ns: 'footer' })}
                            </Box>
                            <Link target={"_blank"} href='https://mobilite-mobiliteit.brussels/fr' rel='preconnect noreferrer noopener' color='inherit'>
                                <Image src={'/img/footer/bxl_mobilite_logo.png'} alt={'logo_bxl_mobilite'}
                                       width={302}
                                       height={105} />
                            </Link>
                            <Typography  sx={{mt:5}}>
                                {t('_TKEY_FOOTER_BRUXELLES_MOBILITE_TEXT_2', { ns: 'footer' })}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{  height: 331, p:2 ,borderTopRightRadius: '40px',borderBottomLeftRadius: '40px'}}>
                            <Box sx={{
                                textAlign:'right',
                                fontFamily: 'Lato',
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: "1.2rem",
                                lineHeight: "11px",
                                pr:3,
                                mb:1
                            }}>
                                {t('_TKEY_FOOTER_CROIX_ROUGE_TEXT_1', { ns: 'footer' })}
                            </Box>
                                <Link target={"_blank"} href='https://www.croix-rouge.be/' rel='preconnect noreferrer noopener' color='inherit'>
                                    <Image src={'/img/footer/red_cross_logo.png'} alt={'logo_croix_rouge'}
                                           width={302}
                                           height={134} />
                                </Link>
                                <MenuList dense>
                                    <a href={'tel:+32 2 512 11 45'}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <LocalPhoneIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText sx={{color:'#0645AD'}}>+32 2 512 11 45</ListItemText>
                                        </MenuItem>
                                    </a>
                                    <a  href={"mailto:info@premierssecoursenroute.brussels"}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <MailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText sx={{color:'#0645AD'}}>
                                            <Trans
                                                i18nKey="footer:_TKEY_FOOTER_CONTACT_EMAIL"
                                                components={defaultComponent()}
                                            />
                                        </ListItemText>
                                    </MenuItem>
                                    </a>
                                    <a target={"_blank"} href={"https://goo.gl/maps/6NF3qiuzSQvkbCBS7"}>
                                    <MenuItem  dense>
                                        <ListItemIcon>
                                            <LocationOnIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText sx={{color:'#0645AD'}}>
                                            <div dangerouslySetInnerHTML={
                                                {__html: t('_TKEY_FOOTER_CROIX_ROUGE_TEXT_2', { ns: 'footer', interpolation: {escapeValue: false}})}
                                            } />
                                        </ListItemText>
                                    </MenuItem>
                                    </a>
                                    <MenuItem  dense>
                                        <ListItemIcon>
                                            <AccessTimeIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                                <Trans
                                                    i18nKey="footer:_TKEY_FOOTER_CROIX_ROUGE_TEXT_3"
                                                    components={defaultComponent()}
                                                />
                                        </ListItemText>
                                    </MenuItem>
                                </MenuList>
                        </Paper>
                    </Grid>
                </Grid>

            </Container>
            <Box p={5}>

                <Box textAlign='center' display='flex' justifyContent='center'>
                    <Typography variant='caption'>
                        <Trans
                            i18nKey="footer:_TKEY_FOOTER_GENERAL_DESCRIBE_1_PART_1"
                            components={defaultComponent()}
                        />
                        <Link target={"_blank"} href='https://www.croix-rouge.be/donnees-personnelles' rel='preconnect noreferrer noopener' color='inherit'>{t('_TKEY_FOOTER_GENERAL_DESCRIBE_1_PART_2', { ns: 'footer' })}</Link>.
                    </Typography>
                </Box>
                <Box textAlign='center' display='flex' justifyContent='center' m={5}>
                    <Typography variant='caption'>
                        <Trans
                            i18nKey="footer:_TKEY_FOOTER_REALISATION"
                            components={defaultComponent()}
                        /><Link target={"_blank"} href='https://www.linkedin.com/in/abbas-khatib' rel='preconnect noreferrer noopener' color='inherit'>Abbas Khatib</Link>   &  <Link target={"_blank"} href='https://www.linkedin.com/in/laurentsantin' rel='preconnect noreferrer noopener' color='inherit'>Laurent Santin</Link>
                    </Typography>
                </Box>
                <Box textAlign='center' display='flex' justifyContent='center' >
                    <Typography variant='caption'>
                        ©{new Date().getFullYear()} {t('_TKEY_FOOTER_ALL_RIGHTS_RESERVED', { ns: 'footer' })}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
export default Footer
