import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, DialogTitle, Fade, IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import {Trans} from "next-i18next";
import {defaultComponent} from "../../../utils/i8next/component";
import CampaignIcon from '@mui/icons-material/Campaign';
import {useEffect} from "react";
import {useRouter} from "next/router";
function CloseIcon() {
    return null;
}
const DialogGeneralMessage = ({typeView='desktop'}) => {
    const [open, setOpen] = React.useState(false)
    const [isHidden, setIsHidden] = React.useState(false)

    const router = useRouter()

    useEffect(()=>{
        if(router.pathname === '/' && typeView==='desktop'){
          setOpen(true)
        }else{
            if(!open){
                setOpen(false)
            }
        }
    },[])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return(<>
        <Dialog
            fullScreen={false}
            maxWidth={'md'}
            open={open}
            aria-labelledby="responsive-dialog-title"
            onClose={handleClose}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Trans
                    i18nKey="common:_TKEY_POPUP_PORTAL_DIALOG_HEADER"
                    components={defaultComponent()}
                />

            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Box sx={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: "18px",
                    lineHeight: '22px',
                }}>
                    <Trans
                        i18nKey="common:_TKEY_POPUP_PORTAL_DIALOG_CONTENT"
                        components={defaultComponent()}
                    />

                </Box>
                {/*<Box mt={5} >*/}
                {/*    <div className="google-map-code">*/}
                {/*        <iframe*/}
                {/*            title="google-map"*/}
                {/*            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d629.9850908427504!2d4.3493251292640895!3d50.83226854575266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c466aaccf601%3A0x23419c97a1fd1e6a!2sRue%20de%20l&#39;H%C3%B4tel%20des%20Monnaies%2040%2C%201060%20Saint-Gilles!5e0!3m2!1sfr!2sbe!4v1670343812969!5m2!1sfr!2sbe"*/}
                {/*            width="100%" height="450"  style={{border: 0}}  allowFullScreen="" loading="lazy" />*/}
                {/*    </div>*/}
                {/*</Box>*/}
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} sx={{ textTransform:'none',borderRadius: "50px",width: '100px', height:'36px',fontSize: '16px',}} onClick={handleClose} >
                    <Trans
                        i18nKey="common:_TKEY_POPUP_PORTAL_L_ACTION"
                        components={defaultComponent()}
                    />
                </Button>
            </DialogActions>
        </Dialog>
        <Box onClick={handleClickOpen} sx={{
            display: isHidden?'none':'flex',
            pb: typeView==='desktop'?0:1,
            }}>
            <Fade in={!isHidden}>

                <Button sx={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: typeView==='desktop'?"18px":'14px',
                    display: 'flex',
                    letterSpacing: '0.05em',
                    textTransform: 'none',
                    mt: typeView==='desktop'?0:1,
                }}
                        color={"primary"}
                        variant={"outlined"}
                        startIcon={<CampaignIcon  sx={{
                            animation: 'ripple 1.5s infinite ease-in-out',
                            '@keyframes ripple': {
                                '0%': {
                                    fontSize: "25px",
                                    transform: 'scale(.8)',
                                    opacity: 1,
                                },
                                '100%': {
                                    fontSize: "25px",
                                    transform: 'scale(1)',
                                    opacity: 0,
                                },
                            }
                        }}  />}
                >
                    <Box >
                        <Trans
                            i18nKey="common:_TKEY_POPUP_PORTAL_BUTTON_TEXT"
                            components={defaultComponent()}
                        />
                    </Box>
                </Button>
            </Fade>

        </Box>
    </>)
}

export  default DialogGeneralMessage