import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ListItemButton, Stack, Toolbar} from "@mui/material";
import MenuLanguage from "../menu-language.componement";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HelpIcon from '@mui/icons-material/Help';
import MailIcon from '@mui/icons-material/Mail';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import HomeIcon from '@mui/icons-material/Home';
import {useRouter} from "next/router";
import {signOut, useSession} from "next-auth/react";
import LogoutIcon from "@mui/icons-material/Logout";
import {useTranslation} from "next-i18next";
import Image from "next/legacy/image";
export default function MenuTopAnonymousCandidateMobile() {
    const {  status: isConnected } = useSession()
    const {t} = useTranslation(['menu']);
    const router = useRouter()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const redirectTo =(route)=>()=>{
         setState({ ...state, left: false });
         router.push(route)
    }


    const list = (anchor) => (
        <Box
            sx={{ width: "100vw" }}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Toolbar variant={'dense'} sx={{
                display: 'flex',
                justifyContent:'space-between'
            }}>
                <Button  onClick={()=>router.push('/')} color={'primary'}  size={'small'} variant="contained" sx={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: { xs: '12px',sm:'12px', md:'16px' },
                    lineHeight: "19px",
                    letterSpacing: "0.07em"
                }}>{t('_TKEY_MENU_1', { ns: 'menu' })}</Button>
                <Box>
                    <Stack direction="row" spacing={2}>
                        <MenuLanguage size={'small'} id={'menu-language-mobile-candidate'} />
                    </Stack>
                </Box>
            </Toolbar>
            <Box sx={{display: 'flex', mx:2 }}>
                <Box sx={{   display: 'flex', flexGrow: 1}}>
                    <Button
                        onClick={redirectTo("/")}
                        startIcon={ <Image src={'/img/icons/menu.svg'} alt={'open_mobile_menu_icon'}
                                   width={19}
                                   height={10} />
                        } color={'inherit'}>{t('_TKEY_MENU_7', { ns: 'menu' })}</Button>
                </Box>
                <Box sx={{  display: 'flex', flexGrow: 1, justifyContent:'end'}}>
                    <Button onClick={toggleDrawer(anchor, false)}   endIcon={<CloseOutlinedIcon/>} color={"inherit"}>
                        {t('_TKEY_MENU_8', { ns: 'menu' })}
                    </Button>
                </Box>
            </Box>

            <List onClick={redirectTo("/")}  >
                <ListItemButton>
                    <ListItemIcon >
                        <HomeIcon color={"secondary"} />
                    </ListItemIcon>
                    <ListItemText  color={"secondary"} primary={t('_TKEY_MENU_9', { ns: 'menu' })} />
                </ListItemButton>
            </List>
            <List onClick={isConnected==='unauthenticated'?redirectTo("/auth/login"):redirectTo("/mon-espace-apprenant")}>
                <ListItemButton>
                    <ListItemIcon>
                        <AccountCircleRoundedIcon color={"secondary"} />
                    </ListItemIcon>
                    <ListItemText color={"secondary"} primary={t('_TKEY_MENU_5', { ns: 'menu' })} />
                </ListItemButton>
            </List>
            {
                isConnected ==='unauthenticated' && <>

                    <List  onClick={redirectTo("/register")} >
                        <ListItemButton>
                            <ListItemIcon >
                                <PersonAddAltRoundedIcon color={'secondary'} />
                            </ListItemIcon>
                            <ListItemText color={'secondary'} primary={t('_TKEY_MENU_4', { ns: 'menu' })} />
                        </ListItemButton>
                    </List>
                </>
            }
            {
                isConnected ==='authenticated' &&
                    <List onClick={() => signOut()} >
                        <ListItemButton>
                            <ListItemIcon>
                                <LogoutIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText color={"secondary"} primary={t('_TKEY_MENU_6', { ns: 'menu' })} />
                        </ListItemButton>
                    </List>
            }

            <List onClick={redirectTo("/contact")} >
                <ListItemButton>
                    <ListItemIcon>
                        <MailIcon color={'secondary'} />
                    </ListItemIcon>
                    <ListItemText  color={'secondary'} primary={t('_TKEY_MENU_3', { ns: 'menu' })} />
                </ListItemButton>
            </List>
            <List onClick={redirectTo("/aide")}  >
                <ListItemButton>
                    <ListItemIcon>
                        <HelpIcon color={'secondary'} />
                    </ListItemIcon>
                    <ListItemText  color={'secondary'} primary={t('_TKEY_MENU_2', { ns: 'menu' })} />
                </ListItemButton>
            </List>

        </Box>
    );

    return (
        <div>
                <React.Fragment key={'left'}>
                    <Button
                        onClick={toggleDrawer('left', true)}
                        startIcon={<MenuOutlinedIcon/>} color={'inherit'}>MENU</Button>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        {list('left')}
                    </SwipeableDrawer>
                </React.Fragment>
        </div>
    );
}