import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.secondary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuLanguage({size,id='menu-language'}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onlyHandleClose = () => {
        setAnchorEl(null);
    };
    const handleClose = (currentLanguage) => {
        setAnchorEl(null);
        if(currentLanguage==='fr'){
            window.location.replace('https://pser.brussels');
        }else{
            window.location.replace('https://ehow.brussels');
        }
    };
    return (
        <div>
            <Button
                sx={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
                }}
                size={size}
                startIcon={<LanguageIcon/>}
                color={'secondary'}
                id={id}
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {process.env.NEXT_PUBLIC_WEB_SITE_LANGUAGE==='fr'?'FR':'NL'}
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={onlyHandleClose}
            >
                <MenuItem onClick={()=>handleClose(process.env.NEXT_PUBLIC_WEB_SITE_LANGUAGE==='fr'?'fr':'nl')} disableRipple>
                    <ListItemText>{process.env.NEXT_PUBLIC_WEB_SITE_LANGUAGE==='fr'?'Français':'Nederlands'}</ListItemText>
                    <CheckIcon/>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={()=>handleClose(process.env.NEXT_PUBLIC_WEB_SITE_LANGUAGE==='nl'?'fr':'nl')} disableRipple >
                    <ListItemText>{process.env.NEXT_PUBLIC_WEB_SITE_LANGUAGE==='nl'?'Français':'Nederlands'}</ListItemText>
                    <CheckBoxOutlineBlankIcon />
                </MenuItem>
            </StyledMenu>
        </div>
    );
}