import React, {useEffect, useState} from 'react'
import {
    AppBar,
    Box, Button,
    Stack,
    Toolbar,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import MenuLanguage from "../../menu/menu-language.componement";
import { useRouter } from 'next/router'
import {signOut, useSession} from "next-auth/react"
import LogoutIcon from '@mui/icons-material/Logout';
import {useTranslation} from "next-i18next";
import Link from '../../../src/Link';
import DialogGeneralMessage from "../../dialog/dialog-general-message/dialog-general-message.component";

const buttonLEftCss={
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.05em",
}
const NavbarTopAnonymousCandidate = ()=>{
    const { data: session, status: isConnected } = useSession()
    const {t} = useTranslation(['menu']);
    const router = useRouter()
    const [scrollY,setScrollY] =  useState(0)
    useEffect(()=>{
        const onScroll = e => {
            setScrollY(window.scrollY)
        };
        window.addEventListener("scroll", onScroll);
    },[scrollY])
    return (
        <AppBar position="sticky" elevation={scrollY!==0?3:0} color={'inherit'} sx={{ display: { xs: 'none' , sm: 'none', md:'none',lg:'block' } }}>
            <Toolbar variant={'dense'}>
                <Box sx={{
                    display: 'flex',
                    flexGrow: 1,
                    mt:1 ,mb:1
                }}>
                    <Button  component={Link} noLinkStyle  href={'/'} startIcon={<HomeIcon/>} color={'primary'}  size={'medium'} variant="contained" sx={{
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        letterSpacing: "0.07em",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
                    }}>{t('_TKEY_MENU_1', { ns: 'menu' })}</Button>
                    <Box sx={{ml:1}}>
                        <DialogGeneralMessage />
                    </Box>


                </Box>
                <Box  sx={{display:'flex'}}>
                    <Stack direction="row" spacing={2}>
                        <Button component={Link} noLinkStyle  href={'/aide'} size={'large'}  color={'secondary'} sx={buttonLEftCss}><u>{t('_TKEY_MENU_2', { ns: 'menu' })}</u></Button>
                        <Button component={Link} noLinkStyle  href={'/contact'} size={'large'} color={'secondary'} sx={buttonLEftCss}><u>{t('_TKEY_MENU_3', { ns: 'menu' })}</u></Button>
                        {
                            isConnected ==='unauthenticated' &&
                            <>
                                <Button  component={Link} noLinkStyle  href={'/register'} size={'large'} color={'secondary'} sx={buttonLEftCss}><u>{t('_TKEY_MENU_4', { ns: 'menu' })}</u></Button>
                            </>
                        }

                        <Button component={Link} noLinkStyle  href={isConnected==='unauthenticated'?'/auth/login':'/mon-espace-apprenant'} size={'large'} color={'secondary'}  variant={"outlined"} sx={{
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "0.05em",
                            textTransform: "uppercase"
                        }}   >{t('_TKEY_MENU_5', { ns: 'menu' })}</Button>
                        {
                            isConnected ==='authenticated' && <Button startIcon={<LogoutIcon />} onClick={() => signOut({redirect: true, callbackUrl: "/auth/login"})} size={'large'} color={'secondary'}  variant={"outlined"} sx={{
                                fontFamily: "Lato",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "17px",
                                letterSpacing: "0.05em",
                                textTransform: "uppercase",
                                borderRadius: '5px'
                            }}   >{t('_TKEY_MENU_6', { ns: 'menu' })}</Button>
                        }
                        <MenuLanguage size={'large'} id={'menu-language-desktop-candidate'} />
                    </Stack>
                </Box>
            </Toolbar>
        </AppBar>
    )

}
export  default  NavbarTopAnonymousCandidate