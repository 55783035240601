import React, {useEffect} from 'react'
import Footer from "../../footer/footer";
import NavbarTopAnonymousCandidate
    from "../../navbar/navbar-top-anonymous-candidate/navbar-top-anonymous-candidate.component";
import {Container} from "@mui/material";
import NavbarTopAnonymousCandidateMobile
    from "../../navbar/navbar-top-anonymous-candidate-mobile/navbar-top-anonymous-candidate-mobile.component";
import {signOut, useSession} from "next-auth/react";

export default function LayoutAnonymousCandidate({ children }) {
    const {data: session} = useSession()
    useEffect(() => {
        if (session?.error === "RefreshAccessTokenError") {
           signOut({redirect: true, callbackUrl: "/auth/login"})
        }
    }, [session]);
    return (
        <>
            <NavbarTopAnonymousCandidate   />
            <NavbarTopAnonymousCandidateMobile  />
            <Container maxWidth={'lg'}>{children}</Container>
            <Footer />
        </>
    )
}
